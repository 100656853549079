let baseUrl = "api";
// 上传图片接口
let actionUrl = baseUrl + "/web/tool/file/upload";

let videoUrl = baseUrl + "/web/tool/video/upload";
// 图片路劲
let imageUrl = "https://ysf.java.linglongpet.com/";

let titleName = "盟数后台系统";
export { baseUrl, actionUrl, imageUrl, videoUrl, titleName };
